import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../index";

interface UserState {
  isLoading: boolean;
  isSidebarExpanded: boolean;
  name: string;
  email: string;
  categoriesActiveButton: string;
  userActiveButton:string
  manageStateActiveButton:string
  chefActiveButton:string
  chefStatusActiveButton:string
  userStatusActiveButton:string
  retailerStatusActiveButton:string
  dishStatusActiveButton:string
  CMSActiveButton:String
}

const initialState: UserState = {
  isLoading: false,
  isSidebarExpanded: true,
  name: localStorage.getItem('name') || '',
  email: 'admin@gmail.com',
  categoriesActiveButton: 'Categories',
  userActiveButton:'Order Details',
  chefActiveButton:'Session',
  chefStatusActiveButton:'All',
  userStatusActiveButton:'All',
  dishStatusActiveButton:'Overview',
  retailerStatusActiveButton:'All',
  manageStateActiveButton:'Order Details',
  CMSActiveButton:'Set Standard Session Amount'

};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    expandSidebar(state) {
      state.isSidebarExpanded = true;
    },
    unexpandSidebar(state) {
      state.isSidebarExpanded = false;
    },
    updateProfile(state, action: PayloadAction<{ name: string; email: string }>) {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    setCategoriesActiveButton(state, action: PayloadAction<string>) {
      state.categoriesActiveButton = action.payload;
    },
    setUserActiveButton(state, action: PayloadAction<string>) {
      state.userActiveButton = action.payload;
    },
    setChefActiveButton(state, action: PayloadAction<string>) {
      state.chefActiveButton = action.payload;
    },
    setChefStatusActiveButton(state, action: PayloadAction<string>) {
      state.chefStatusActiveButton = action.payload;
    },
    setUserStatusActiveButton(state, action: PayloadAction<string>) {
      state.userStatusActiveButton = action.payload;
    },
    setRetailerStatusActiveButton(state, action: PayloadAction<string>) {
      state.retailerStatusActiveButton = action.payload;
      
    },
    setDishStatusActiveButton(state, action: PayloadAction<string>) {
      state.dishStatusActiveButton = action.payload;
    },
    setManageStatusActiveButton(state, action: PayloadAction<string>) {
      state.manageStateActiveButton = action.payload;
    },
    setCMSActiveButton(state, action: PayloadAction<string>) {
      state.CMSActiveButton = action.payload;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  expandSidebar,
  unexpandSidebar,
  updateProfile,
  setCategoriesActiveButton,
  setUserActiveButton,
  setChefActiveButton,
  setChefStatusActiveButton,
  setUserStatusActiveButton,
  setDishStatusActiveButton,
  setRetailerStatusActiveButton,
  setManageStatusActiveButton,
  setCMSActiveButton
} = userSlice.actions;

export const setSideBarOpened = (): AppThunk => async (dispatch) => {
  dispatch(expandSidebar());
};

export const setSideBarClosed = (): AppThunk => async (dispatch) => {
  dispatch(unexpandSidebar());
};

export const updateProfileAsync = (profile: { name: string; email: string }): AppThunk => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch(updateProfile(profile));
    }, 1000);
  };
};

export default userSlice.reducer;
