import { useState } from 'react'
import {
  Cart,
  Container,
  Form,
  CheckboxContainer,
  CheckBoxforgetContainer
} from './style'
import loginIcon from '../../../assets/images/login-icon.svg'
import { IconInput, Textinput } from 'components/particles/forms/input';
import Button from 'components/particles/primary-button';
import { useNavigate } from 'react-router-dom';
import { siteRoutes } from 'utils/helpers/enums/routes.enums';
import useLogin from './useHook';

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const {login} = useLogin()
  const navigate = useNavigate()

  const handleChange = (field: any, value: any) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const submit = (e:any) =>{
    e.preventDefault()
    login(formData)
  }

  return (
    <Container>
      <Cart>
        <img src={loginIcon} alt='img' />
        <div className='login-cart-data'>
          <h1>Login to your Account</h1>
          <p>Welcome back! please enter your detail</p>
          <Form onSubmit={submit}>
            <Textinput
              placeholder="Email"
              onchange={(value) => handleChange('email', value)}
              stateValue={formData.email}
            />
            <IconInput
              placeholder="Password"
              onchange={(value: any) => handleChange('password', value)}
              stateValue={formData.password}
            />
            <CheckBoxforgetContainer>
              <CheckboxContainer>
                <input type="checkbox" />
                <label>Remember me</label>
              </CheckboxContainer>
              <h2 className='forget-label' onClick={()=>{navigate(siteRoutes.ResetPassword)}}>Forget Password?</h2>
            </CheckBoxforgetContainer>
            <div className='login-button'>
            <Button title="Login" round="full" width="100%" fill={true} fillColor="blue"/>
            </div>

          </Form>
        </div>
      </Cart>
    </Container>
  )
}
