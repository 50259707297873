import { FC, useState } from 'react'
import {
    Cart,
    Container,
    Form,
  } from './style'
import { IconInput} from 'components/particles/forms/input'
import Button from 'components/particles/primary-button'
import login from '../../../assets/images/login-icon.svg'

 const ForgetPassword:FC =() => {
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: ''
      });
      


    const handleChange = (field: any, value: any) => {
        setFormData({
          ...formData,
          [field]: value
        });
      };
  return (
    <Container>
    <Cart>
      <img src={login} alt='img' />
      <div className='forget-cart-data'>
        <h1>Reset your Password</h1>
        <p>Please enter your new password and confirm password</p>
        <Form>
          <IconInput
            placeholder="Password"
            onchange={(value: any) => handleChange('newPassword', value)}
            stateValue={formData.newPassword}
          />
          <IconInput
            placeholder="Confirm Password"
            onchange={(value: any) => handleChange('confirmPassword', value)}
            stateValue={formData.confirmPassword}
          />

          <Button title="Confirm" round="full" width="100%" fill={true} />

        </Form>
      </div>
    </Cart>
  </Container>
  )
}

export default ForgetPassword