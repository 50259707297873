import React, { useState } from 'react'
import {
    Container
} from './style'
import AddSessionAmountPopup from 'utils/helpers/models/popus/Settings/session-amount'
import Button from 'components/particles/primary-button'

export default function SessionAmount() {
    const [modalIsOpen , setIsOpen] = useState(false)
  return (
    <Container>
      <div className='title'>
      <h1>Set Standard Session Amount</h1>
      <Button title="Add Amount" round="full" width="22rem" fill={true} onclick={()=>{setIsOpen(true)}} backgroundColor='var(--secondary)'/>

      </div>
      <AddSessionAmountPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div className='amount-container'>
        <div className='amount-cart'>
          <h1>Session Amount</h1>
          <div className='amount'>
          <h3>12</h3><h4>$</h4>
          </div>
          <Button title="Edit" round="full" width="16rem" fill={true} backgroundColor='var(--secondary)'/>

        </div>
      </div>
    </Container>
  )
}
