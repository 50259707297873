import { ChangeEvent, FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../../assets/images/close.svg'
import Button from 'components/particles/primary-button'

interface AddSessionAmountModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen:boolean
  }

  
const AddSessionAmountPopup:FC<AddSessionAmountModelProps> = ({modalIsOpen , setIsOpen}) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
          setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        console.log(selectedImage);
        
    }
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        height: '36rem',
        borderRadius: '3rem',
    },
};
function closeModal() {
    setIsOpen(false);
}

  return (
    <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
>
<button className='close-button' onClick={closeModal}>
                <img src={close} alt="img"  />
            </button>
    <div className='model-container'>

        <div className='popup-title' style={{ width: '100%', textAlign: "center", marginTop: "4rem" }}>
            <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Add Standard Session Amount</h2>
        </div>
        <div className='add-ingredient-container'>
            <input type="text" placeholder='Session Amount' />
        </div>

        <div className='add-button'>
            <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true}/>
        </div>
    </div>
</Modal>
  )
}

export default AddSessionAmountPopup