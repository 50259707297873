import React, { ChangeEvent, FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../assets/images/close.svg'
import Button from 'components/particles/primary-button'
import { SearchIconInput } from 'components/particles/forms/input'
import img from '../../../../../assets/images/input-img-icon.svg'
import { Container } from './style'


interface IngredientModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen: boolean
}


const IngredientsPopup: FC<IngredientModelProps> = ({ modalIsOpen, setIsOpen }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState({
        name: '',
        email: '',
        gender: '',
        price:'',
        quantity:''
    })

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        console.log(selectedImage);

    }
    const search = () => {
        console.log('Search.....', searchQuery);

    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2rem',
            width: '68.5rem',
            height: '70.7rem',
            borderRadius: '3rem'
        },
    };
    function closeModal() {
        setIsOpen(false);
    }
    const handleChange = (field: any, value: any) => {
        setSearchQuery({
            ...searchQuery,
            [field]: value
        });
    };

    function closeImg() {
        setSelectedImage(null)
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Container>
                <button className='close-button' onClick={closeModal}>
                    <img src={close} alt="img" />
                </button>
                <div className='model-container'>

                    <div className='popup-title' style={{ width: '100%', textAlign: "center", marginTop: "4rem" }}>
                        <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Add Ingredients</h2>
                    </div>
                    <div className='add-ingredient-container'>
                        <div className='image-container'>
                            <div className='image-input-wrapper'>
                                {selectedImage ? (
                                    <>
                                        <img className='uploaded-image' src={selectedImage} alt="Selected" />
                                        <img className='close-icon' src={close} alt="Remove" onClick={closeImg} />
                                    </>
                                ) : (
                                    <>
                                        <label htmlFor="image-input">
                                            <img src={img} alt="Upload" />
                                        </label>
                                        <input
                                            id="image-input"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className='hidden-input'
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder='Name'
                            onChange={(value) => handleChange('name', value)}
                        />
                        <input
                            type="text"
                            placeholder='Price'
                            onChange={(value) => handleChange('price', value)}
                        />
                        <div className='quantity'>
                        <input
                            type="text"
                            placeholder='Quantity'
                            onChange={(value) => handleChange('quantity', value)}
                        />
                            <div className='quantity-unit'>
                                <SearchIconInput placeholder='Quantity' onchange={(value) => handleChange('gender', value)} option={['Select', 'Bag', 'Bar', 'Block', 'Box', 'Carton', 'Bottle']} />
                            </div>
                        </div>
                        <SearchIconInput placeholder='Dietary' onchange={(value) => handleChange('email', value)} option={['meet', 'vagetable', 'fruit', 'sweets']} />
                        <SearchIconInput placeholder='Categories' onchange={(value) => handleChange('gender', value)} option={['meet', 'vagetable', 'fruit', 'sweets']} />
                    </div>
                    <div className='add-button'>
                        <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true} />
                    </div>
                </div>
            </Container>
        </Modal>
    )
}

export default IngredientsPopup