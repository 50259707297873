import { useState } from 'react'
import { AllManageStateContainer, Cart, Container } from './style'
import OnBoardingPopup from 'utils/helpers/models/popus/Settings/onboarding'
import Button from 'components/particles/primary-button'

export default function OnBoarding() {
    const [modalIsOpen, setIsOpen] = useState(false)
    return (
        <Container>
            <div className='title'>
                <h1>Onboarding Screen</h1>
                <Button title="Add  Onboarding Screen" round="full" onclick={() => setIsOpen(true)} width="22rem" fill={true} />

            </div>

            <OnBoardingPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />


            <AllManageStateContainer>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Text 1 </span>
                            <h1>100+ Premium
                                Indian Recipes made for you!</h1>
                            <span>Text 2  </span>
                            <h1>Welcome to Cook</h1>
                            <span>Text 3  </span>
                            <h1>Discover new recipes, learn culinary skills, and create delicious meals with
                                Cook with my Mom!
                            </h1>
                        </div>
                    </div>
                    <Button title="Edit" round="full" width="11.2rem" fill={true} onclick={() => setIsOpen(true)} />
                </Cart>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Text 1 </span>
                            <h1>100+ Premium
                                Indian Recipes made for you!</h1>
                            <span>Text 2  </span>
                            <h1>Welcome to Cook</h1>
                            <span>Text 3  </span>
                            <h1>Discover new recipes, learn culinary skills, and create delicious meals with
                                Cook with my Mom!
                            </h1>
                        </div>
                    </div>
                    <Button title="Edit" round="full" width="11.2rem" fill={true} onclick={() => setIsOpen(true)} />
                </Cart>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Text 1 </span>
                            <h1>100+ Premium
                                Indian Recipes made for you!</h1>
                            <span>Text 2  </span>
                            <h1>Welcome to Cook</h1>
                            <span>Text 3  </span>
                            <h1>Discover new recipes, learn culinary skills, and create delicious meals with
                                Cook with my Mom!
                            </h1>
                        </div>
                    </div>
                    <Button title="Edit" round="full" width="11.2rem" fill={true} onclick={() => setIsOpen(true)} />
                </Cart>

            </AllManageStateContainer>
        </Container>
    )
}
