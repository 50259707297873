import { FC, Fragment, useState } from 'react'
import { Container, TabeContainer, TabeCart, Tbody } from './style';
import Pagination from 'components/particles/pagination/pagination';
import categoriesDarkIcon from '../../assets/images/categories-tab-dark-icon.svg'
import categoriesIcon from '../../assets/images/categories-tab-icon.svg'
import dietaryIcon from '../../assets/images/dietary-white-icon.svg'
import dietaryDarkIcon from '../../assets/images/diteryIcon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setCategoriesActiveButton } from 'store/user-slice';
import AddCategoryModal from 'utils/helpers/models/popus/categories-popup/add-category';
import SelectIngredientModel from 'utils/helpers/models/popus/categories-popup/select-ingredients';
import { tableData } from 'utils/helpers/dummyData';
import link from '../../assets/images/link.svg'
import NoDataFound from 'components/particles/no-data-found'
import edit from '../../assets/images/edit.svg'
import Button from 'components/particles/primary-button';
import { SearchIconInput } from 'components/particles/forms/input';

const Categories: FC = () => {
  const dispatch = useDispatch()
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ingredientModalIsOpen, setIngredientIsOpen] = useState(false);
  const { categoriesActiveButton } = useSelector((state: any) => state.user);
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    email: '',
    gender: ''
  })
  const search = () => {
    console.log('Search.....', searchQuery);

  }


  const openIngredientPopup = () => {
    setIngredientIsOpen(true)
  }

  const limit = 10;
  function fetchNextRecords(offset: number) {
    console.log(offset);

  }

  const handleChange = (field: any, value: any) => {
    setSearchQuery({
      ...searchQuery,
      [field]: value
    });
  };

  const addCategories = () => {
    setIsOpen(true);
  }

  const columns: string[] = [
    'Image',
    'Name',
    'No. of ingredients',
    'Status',
    'Actions'
  ]
  return (
    <Container>
      <div className='categories-title'>
        <h1>Categories Type</h1>
        <Button title="Add Categories" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' onclick={addCategories} />

      </div>

      <TabeContainer >
        <TabeCart active={categoriesActiveButton === 'Categories'} onClick={() => { dispatch(setCategoriesActiveButton('Categories')) }}>
          <div className='categories-tab-img'>
            <img src={categoriesActiveButton === 'Categories' ? categoriesIcon : categoriesDarkIcon} alt="img" />
          </div>
          <h1>
            Categories
          </h1>
        </TabeCart>
        <TabeCart active={categoriesActiveButton === 'Dietary'} onClick={() => { dispatch(setCategoriesActiveButton('Dietary')) }}>
          <div className='categories-tab-img'>
            <img src={categoriesActiveButton === 'Dietary' ? dietaryIcon : dietaryDarkIcon} alt="img" />
          </div>
          <h1>
            Dietary
          </h1>
        </TabeCart>
      </TabeContainer>
      <div className='search-container' >
        <div className='input-fields'>
          <input
            type="text"
            placeholder='Search By Name'
            onChange={(value) => handleChange('name', value)}
          />
         <SearchIconInput placeholder='Status' onchange={(value) => handleChange('email', value)} option={['Gender', 'Male', 'Female']} />
        </div>
        <div className='button-fields'>
          <Button title="Search" round="full" width="16rem" fill={true} />
          <Button title="Reset" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />
        </div>
      </div>
      <div className='data-table'>
        <table>
          <thead>
            <tr>
              {columns.map((column: string, index: number) => {
                return <th scope='col' key={index}>{column}</th>
              })}

            </tr>
          </thead>
          <Tbody>
            {tableData?.length > 0 ? (
              tableData
                ?.map((item, index) => (
                  <tr key={index}>
                    <td >
                      <div className="flex gap-4 items-center">
                        <img src="https://picsum.photos/300" alt="img" width={60} style={{ borderRadius: '50%' }} />
                      </div>
                    </td>
                    <td colSpan={3}>{item.recipeName}</td>
                    <td colSpan={3}>{item.id}</td>
                    <td><label className="toggle-button">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label></td>
                    <td className="actions">
                      <button onClick={openIngredientPopup}
                      >
                        <img src={link} alt='img' />
                        <h5>Ingredients</h5>
                      </button>
                      <img src={edit} alt='img' onClick={addCategories} />
                    </td>
                  </tr>
                ))
            ) : (
              <NoDataFound />
            )}
          </Tbody>
        </table>
      </div>
      <Fragment>
        <Pagination totalRecords={10} perPage={limit} handleFetchNextRecords={fetchNextRecords} />
      </Fragment>

      <AddCategoryModal setIsOpen={setIsOpen} modalIsOpen={modalIsOpen} />
      <SelectIngredientModel setIngredientIsOpen={setIngredientIsOpen} ingredientModalIsOpen={ingredientModalIsOpen} />

    </Container>
  )
}


export default Categories;