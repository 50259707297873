import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: start;
padding: 2rem;
flex-direction: column;
gap: 2rem;


h1{
    color:#1C1C27 ;
    font-weight: 600;
}

.graph-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.graph-left{
    width: 70%;
    background-color:var(--white);
    padding: 3rem;
}
.graph-right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.graph-cart{
    background-color:var(--white);
    width: 35.5rem;
    height: auto;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    gap: 2rem;

    .title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.graph{
    width: 18rem;
}

.dropdown-container{
    /* width: 45%; */
    position: relative;
}

.dropdown-arrow{
    display: flex;
    gap: .5rem;
    border: 1px solid #808D9E;
    padding: 0.5rem;
    border-radius: .5rem;
}

th , td{
    width: 14rem !important;
}

.th{
    width: 10rem !important;
}

@media (max-width:768px){
    .graph-container{
        display: none;
    }
}
`

export const TopTiles = styled.div`
height: auto;
width: 100%;
background-color:var(--white);
display: flex;
justify-content: space-around;
align-items: center;
flex-wrap: wrap;
gap: 2rem;
padding: 2rem;

h1{
    font-size: 3.2rem;

    @media (max-width:992px){
        font-size: 2.4rem;
    }
}
.dashboard-top-tiles-items-data{


}

img{
    width: 84px;
}

@media (max-width:1200px){
img{
    width: 50px;
}
h1{
    font-size: 2.8rem;
}
}
@media (max-width:768px){
img{
    width: 30px;
}
h1{
    font-size: 2.4rem;
}
}
`

export const InfoTile = styled.div<{last:boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-right: ${({last})=> last?'0px':'3px'} solid #F0F0F0;
    padding-right: 3rem;

`

export const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 14rem;
  background-color:var(--white);
  z-index: 10;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .menu-item{
    display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.6rem;

  &:hover {
    background-color: #e0e0e0;
  }
  }
`;

export const TableContainer = styled.div`
width: 100%;
background-color:var(--white);
height: auto;
padding: 1.5rem;
`

