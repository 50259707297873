import React, { useState } from 'react'
import { Container, Status } from './style'
import Button from 'components/particles/primary-button'
import DietaryChoicePopup from 'utils/helpers/models/popus/Settings/dietary-choice'

export default function DietaryChoise() {
  const [modalIsOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <div className='title'>
        <h1>Questions Management</h1>
        <Button title="Add  Question" round="full" width="22rem" fill={true} backgroundColor='var(--secondary)' onclick={() => setIsOpen(true)} />
      </div>
      <DietaryChoicePopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div className='cart'>
        <div className='dietaries-cart'>
            <p>Question no 1</p>
          <h1>What do you like about Indian food?</h1>
            <p>Answer</p>
          <div className='dietaries'>
            <Status >{'Sweet, spicy, and savory flavors'}</Status>
            <Status >{'Sweet, spicy, and savory flavors'}</Status>
          </div>
        </div>
        <Button title="Edit" round="full" width="11rem" fill={true} onclick={() => setIsOpen(true)} />
      </div>
    </Container>
  )
}
