import { createGlobalStyle } from "styled-components";

export const SearchStyles = createGlobalStyle`
.search-container{
    width: 100%;
    background-color:var(--white);
    display: flex;
    gap: 2rem;
    padding: 2rem;
    border-radius: 1rem;
    justify-content: center;
}

.input-fields{
    width: 80%;
    display: flex;
    gap: 1.5rem;
}

select{
    width: 100%;
    font-size: 1.8rem;
    color: var(--text);
}

option{
    font-size: 1.6rem;
}

.search-cart{
    width: 100%;
    display: flex;
    gap: .5rem;
    align-items: center;
    background-color:#FFFFFF1A;
    border-radius: 1rem;
    border: 1px solid #E2E8F0;
    padding: 1rem;
}
`;