import { ChangeEvent, FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../../assets/images/close.svg'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from 'components/particles/primary-button';

interface AddTermAndConditionsModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen: boolean
}


const AddTermAndConditionsPopup: FC<AddTermAndConditionsModelProps> = ({ modalIsOpen, setIsOpen }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [data, setData] = useState({
        heading: '',
        content: 'jsdgkfngn;fdlknb',
    });
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        console.log(selectedImage);

    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            height: '65.6rem',
            width: '86rem',
            borderRadius: '3rem',
        },
    };
    function closeModal() {
        setIsOpen(false);
    }

    const handleContentChange = () => {

    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <button className='close-button'>
                <img src={close} alt="img" onClick={closeModal} />
            </button>
            <div className='model-container'>

                <div className='popup-title' >
                    <h1>Terms & Conditions</h1>
                </div>
                <div className='add-term-condition-container' >
                    <ReactQuill
                        theme="snow"
                        value={data.content}
                        onChange={handleContentChange}
                        className="text-editor"
                    />
                </div>

                <div className='add-button'>
                    <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true} />
                </div>
            </div>
        </Modal>
    )
}

export default AddTermAndConditionsPopup