// import styled from "styled-components";

// export const SidebarItemContainer = styled.li<{ active: boolean }>`
//  display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 1rem;
//   height: 4.5rem;
//   cursor: pointer;
//   ${(props) =>
//     props.active
//       ? `background-color: #98a8b433; color: white; border-right: 3px solid #00B087; border-radius: 30px 0 0 30px;`
//       : `&:hover { background-color: #98a8b433;  color: white; border-radius: 30px 0 0 30px;}`}

// @media (max-width: 992px) {
//     /* display: none; */
//     /* transform: translateX(-100%); */
//     /* position: absolute; */
//     width: 0;
//   }
// `;

// export const IconContainer = styled.div<{ isSidebarExpanded: boolean }>`
//   margin-left: ${(props) => (props.isSidebarExpanded ? '1.5rem' : '0')};
// `;

// export const TextContainer = styled.span<{ isSidebarExpanded: boolean }>`
//   width: ${(props) => (props.isSidebarExpanded ? '18rem' : '0')};
//   margin-left: ${(props) => (props.isSidebarExpanded ? '1.5rem' : '0')};
//   font-size: 1.6rem;
//   color:var(--white);

//   @media (max-width: 992px) {
//     /* display: none; */
//     /* transform: translateX(-100%); */
//     /* position: absolute; */
//     width: 0;
//   }
// `;

// export const AlertDot = styled.div<{ isSidebarExpanded: boolean }>`
//   position: absolute;
//   right: 0.5rem;
//   width: 0.5rem;
//   height: 0.5rem;
//   background-color: var(--primary);
//   border-radius: 50%;
//   font-size: 1.6rem;
//   ${(props) => (props.isSidebarExpanded ? '' : 'top: 0.5rem;')}
// `;

// export const Tooltip = styled.div`
//   position: absolute;
//   left: 100%;
//   margin-left: 1.5rem;
//   padding: 0.5rem;
//   background-color: var(--primary);
//   color:var(--white);
//   font-size: 1.6rem;
//   border-radius: 0.375rem;
//   opacity: 0;
//   visibility: hidden;
//   transform: translateX(-0.75rem);
//   transition: all 0.2s;
//   ${SidebarItemContainer}:hover & {
//     opacity: 1;
//     visibility: visible;
//     transform: translateX(0);
//   }
//  `;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ListItemProps {
  active: boolean;
}

interface IconContainerProps {
  isSidebarExpanded: boolean;
}

interface TextProps {
  isSidebarExpanded: boolean;
}

interface AlertProps {
  isSidebarExpanded: boolean;
}

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
`;

export const SidebarItemContainer = styled.li<ListItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0.75rem;
  height: 4.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: white;

  ${({ active }) =>
    active
      ? `
        background-color: #98a8b433;
        border-right: 3px solid #00B087;
      `
      : `
        &:hover {
          background-color: #98a8b433;
          border-right: 3px solid #00B087;
        }
      `}
`;

export const IconContainer = styled.div<IconContainerProps>`
  ${({ isSidebarExpanded }) => (isSidebarExpanded ? 'margin-left: 1.5rem;' : '')}
`;

export const TextContainer = styled.span<TextProps>`
  overflow: hidden;
  font-size: 1.6rem;
  transition: width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  ${({ isSidebarExpanded }) =>
    isSidebarExpanded ? 'width: 100%; margin-left: 1.5rem;' : 'width: 0;'}
`;

export const AlertDot = styled.div<AlertProps>`
  position: absolute;
  right: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary);
  border-radius: 50%;
  ${({ isSidebarExpanded }) => (isSidebarExpanded ? '' : 'top: 0.5rem;')}
`;

export const Tooltip = styled.div`
  position: absolute;
  left: 100%;
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary);
  color: white;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-0.75rem);
  transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;

  ${SidebarItemContainer}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
`;