import styled from "styled-components";

export const Container = styled.div`
.add-input{
    display: flex;
    gap: 1rem;
}

.input-container{
    display: flex;
    gap: 1rem;
}
`