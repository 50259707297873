import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

.dietaries{
    display: flex;
    gap: 1rem;
}

.cart{
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1{
        display: list-item;
    }

    li{
        font-size: 1.8rem;
    }
}
`

export const Status = styled.span`
padding: 0.5rem 1rem !important;
border-radius: 0.5rem;
background-color: #209A421A;
color:  var(--primary);
font-size: 1.8rem;

`