import { FC, Fragment, useState } from 'react'
import { Container } from './style';
import Pagination from 'components/particles/pagination/pagination';
import IngredientsPopup from 'utils/helpers/models/popus/ingredients-popup';
import Button from 'components/particles/primary-button';
import { tableData } from 'utils/helpers/dummyData';
import edit from '../../assets/images/edit.svg';
import deleteIcon from '../../assets/images/delete.svg'
import NoDataFound from 'components/particles/no-data-found';
import { SearchIconInput } from 'components/particles/forms/input';

const Ingredients: FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    email: '',
    gender: ''
  })

  const search = () => {
    console.log('Search.....', searchQuery);

  }

  const limit = 10;
  function fetchNextRecords(offset: number) {
    console.log(offset);

  }

  const addCategories = () => {
    setIsOpen(true);
  }

  const handleChange = (field: any, value: any) => {
    setSearchQuery({
      ...searchQuery,
      [field]: value
    });
  };

  const columns: any = [
    {
      name: 'Name',
      class: ''
    },
    {
      name: 'Price',
      class: 'th'
    },
    {
      name: 'Quantity',
      class: 'th'
    },
    {
      name: 'Dietary',
      class: 'th'
    },
    {
      name: 'Categories',
      class: 'th'
    },
    {
      name: 'Status',
      class: 'th'
    },
    {
      name: 'Actions',
      class: 'th'
    },
  ]
  return (
    <Container>
      <div className='categories-title'>
        <h1>Ingredients</h1>
        <Button title="Add Ingredients" round="full" width="16.5rem" fill={true} backgroundColor='var(--reset-button)' onclick={addCategories} />

      </div>

      <div className='search-container' >
        <div className='input-fields'>
        <input
            type="text"
            placeholder='Search By Name'
            onChange={(value) => handleChange('name', value)}
          />
          <SearchIconInput placeholder='Search By Name' onchange={(value) => handleChange('name', value)} option={['Categories' , 'Food' , 'Meat' , 'Vagetable' , 'Fruit']}/>
          <SearchIconInput placeholder='Search By Name' onchange={(value) => handleChange('name', value)} option={['Dietaries' , 'Food' , 'Meat' , 'Vagetable' , 'Fruit']}/>
          <SearchIconInput placeholder='Search By Name' onchange={(value) => handleChange('name', value)} option={['Status' , 'Male' , 'Female' ]}/>

        </div>
        <Button title="Search" round="full" width="16rem" fill={true} onclick={search}/>
        <Button title="Reset" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' onclick={search}/>
      </div>
      <div className='data-table'>
        <table>
          <thead>
            <tr>
              {columns.map((column: any, index: number) => {
                return <th className={column.class} key={index}>{column.name}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData
                ?.map((item, index) => (
                  <tr key={index}>
                    <td >
                      <div className="flex gap-4 items-center">
                        <img src={'https://picsum.photos/300'} alt="img" width={60} style={{ borderRadius: '50%' }} />
                      </div>
                      {item.recipeName}
                    </td>
                    <td className='th'>{item.price}</td>
                    <td className='th'>{item.quentity}</td>
                    <td className='th'>{item.type}</td>
                    <td className='th'>{item.categories}</td>
                    <td className='th'><label className="toggle-button">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label></td>
                    <td className="actions th">
                      <img src={edit} alt='img' onClick={addCategories} />
                      <img src={deleteIcon} alt='img' />
                    </td>
                  </tr>
                ))
            ) : (
              <NoDataFound />
            )}
          </tbody>
        </table>
      </div>
      <Fragment>
        <Pagination totalRecords={10} perPage={limit} handleFetchNextRecords={fetchNextRecords} />
      </Fragment>

      <IngredientsPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />

    </Container>
  )
}


export default Ingredients;