import { useState } from 'react'
import { AllManageStateContainer, Cart, Container } from './style'
import AddFAQPopup from 'utils/helpers/models/popus/Settings/FAQ'
import Button from 'components/particles/primary-button'

export default function FAQ() {
  const [modalIsOpen , setIsOpen] = useState(false)
  return (
    <Container>
      <div className='title'>
    <h1>FAQ’s Managements</h1>
    <Button title="Add  FAQ" round="full" width="22rem" fill={true} backgroundColor='var(--secondary)' onclick={()=>setIsOpen(true)}/>

      </div>

    <AddFAQPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />

    
    <AllManageStateContainer>
                <Cart>
                    <div className='banner-img-title'>
                        <div className='banner-title'>
                            <h1>Question</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas cum est voluptatibus fuga omnis voluptates molestiae repellat quam iusto. Eaque, aperiam dolorem optio reiciendis rerum ex asperiores, debitis, alias incidunt sed tempore nulla at doloremque est. Aliquam sapiente quo commodi! Quas cupiditate modi a error temporibus, odit dolores quos animi.</p>
                        </div>
                        </div>
                            <Button title="Edit" round="full" width="16rem" fill={true} onclick={()=>setIsOpen(true)}/>
                </Cart>
                <Cart>
                    <div className='banner-img-title'>
                        <div className='banner-title'>
                            <h1>Question</h1>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas cum est voluptatibus fuga omnis voluptates molestiae repellat quam iusto. Eaque, aperiam dolorem optio reiciendis rerum ex asperiores, debitis, alias incidunt sed tempore nulla at doloremque est. Aliquam sapiente quo commodi! Quas cupiditate modi a error temporibus, odit dolores quos animi.</p>
                        </div>
                        </div>
                            <Button title="Edit" round="full" width="16rem" fill={true} onclick={()=>setIsOpen(true)}/>
                </Cart>

            </AllManageStateContainer>
  </Container>
  )
}
