import  { FC, useState } from 'react'
import {
    Cart,
    Container,
    Form,
  } from './style'
import { Textinput} from 'components/particles/forms/input'
import Button from 'components/particles/primary-button'
import login from '../../../assets/images/login-icon.svg'
import { siteRoutes } from 'utils/helpers/enums/routes.enums'
import { useNavigate } from 'react-router-dom'
import useForget from './useHook'

 const ResetPassword:FC =() => {
    const [formData, setFormData] = useState({
        email: ''
      });
      const { forget } = useForget()
      const navigate = useNavigate()

    const handleChange = (field: any, value: any) => {
        setFormData({
          ...formData,
          [field]: value
        });
      };

      const submit = (e:any) => {
        e.preventDefault()
        forget(formData)
      }
  return (
    <Container>
    <Cart>
      <img src={login} alt='img' />
      <div className='reset-cart-data'>
        <h1>Reset your Password</h1>
        <p>Enter the email address associated with your account and we will send you a link to reset your password.</p>
        <Form onSubmit={submit}>
        <Textinput
              placeholder="Email"
              onchange={(value) => handleChange('email', value)}
              stateValue={formData.email}
            />
          <Button title="Confirm" round="full" width="100%" fill={true} />
        </Form>
        <div className='back-to-sign-in-cart'>
        <span className='back-to-sign-in' onClick={()=>navigate(siteRoutes.login)}>Back to Sign In</span>
        </div>
      </div>
    </Cart>
  </Container>
  )
}

export default ResetPassword