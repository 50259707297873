
import { FC, useState } from 'react';
import Modal from 'react-modal'
import close from '../../../../../../assets/images/close.svg'
import { ingredientData } from 'utils/helpers/dummyData';
import Button from 'components/particles/primary-button';

interface CategoriesModelProps {
  setIngredientIsOpen: (ingredientModalIsOpen: boolean) => void,
  ingredientModalIsOpen: boolean
}

const SelectIngredientModel: FC<CategoriesModelProps> = ({ setIngredientIsOpen, ingredientModalIsOpen }) => {


  function closeModal() {
    setIngredientIsOpen(false);
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '2rem',
      width: '68.5rem',
      height: '77rem',
    },
  };
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    email: '',
    gender: ''
  })
  const search = () => {
    console.log('Search.....', searchQuery);

  }

  const handleChange = (field: any, value: any) => {
    setSearchQuery({
      ...searchQuery,
      [field]: value
    });
  };
  return (
    <Modal
      isOpen={ingredientModalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <button className='close-button' onClick={closeModal}>
        <img src={close} alt="img" />
      </button>
      <div className='popup-title' style={{ width: '100%', textAlign: "center", marginTop: "4rem" }}>
        <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Select Ingredients</h2>
      </div>
      <div className='search-container' >
        <div className='input-fields'>
          <input
            type='text'
            placeholder='Search By Name'
            onChange={(e) => { setSearchQuery({ ...searchQuery, name: e.target.value }) }}
          />
        </div>
        <Button title="Search" round="full" width="16rem" fill={true} onclick={search} />
      </div>
      <div className='categories-model-ingredient-container p-custom-scrollbar-8 custom-scrollbar'>
        {
          ingredientData.map((item, index) => {
            return (

              <div className='ingredient-data' key={index}>
                <input type="checkbox" />
                <img src={item.image} alt="img" />
                <h5>{item.name}</h5>
              </div>
            )
          })
        }
      </div>
      <div className='add-button' >
        <Button title="Add" round="full" width='51.8rem' backgroundColor='var(--primary)' fill={true} />
      </div>
    </Modal>
  )
}
export default SelectIngredientModel