import styled from "styled-components";

export const Container = styled.div`

h1{
    font-weight: 600;
}


`

