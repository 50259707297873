import styled from "styled-components";

export const Container = styled.div`
.popup-title{
    width: 100%; 
    text-align: center; 
    margin-top: 4rem;

    h2{
        font-weight: 600;
    }
}
`