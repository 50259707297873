import { Apis } from 'libs/apis'
import React from 'react'

interface body{
    email:string
}

export default function useForget() {
    const forget =async (body:body) => {
        await Apis.forget(body)
        .then((res)=>{console.log(res) })
        .catch((error)=>{console.log(error)})
    }
  return {forget}
}
