import { FC, useState } from 'react'
import { Container, InnerContainer, Cart } from './style';
import AddBannersPopup from 'utils/helpers/models/popus/banners-popup/add-banner';
import Button from 'components/particles/primary-button';

interface ManageStateProps {

}

const ManageState: FC<ManageStateProps> = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const addBanners = () => {
        setIsOpen(true);
    }
    return (
        <Container>
            <div className='title'>
                <h1>Banner list</h1>
                <Button title="Add Banners" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' onclick={addBanners} />

            </div>
            <InnerContainer>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Banner Name </span>
                            <h1>Banner Name </h1>
                            <span>Banner Name </span>
                            <p>Select ingredients
                                App suggests cooks offering dishes with selected ingredients
                            </p>

                        </div>
                    </div>
                    <div className='banner-right'>

                        <div className='status'>

                            <h1>Status</h1>
                            <label className="toggle-button">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className='button'>
                            <Button title="Edit" round="full" width="16rem" fill={true} onclick={addBanners}/>
                            <Button title="Delete" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />
                        </div>
                    </div>
                </Cart>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Banner Name </span>
                            <h1>Banner Name </h1>
                            <span>Banner Name </span>
                            <p>Select ingredients
                                App suggests cooks offering dishes with selected ingredients
                            </p>

                        </div>
                    </div>
                    <div className='banner-right'>

                        <div className='status'>

                            <h1>Status</h1>
                            <label className="toggle-button">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className='button'>
                            <Button title="Edit" round="full" width="16rem" fill={true} onclick={addBanners}/>
                            <Button title="Delete" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />
                        </div>
                    </div>
                </Cart>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Banner Name </span>
                            <h1>Banner Name </h1>
                            <span>Banner Name </span>
                            <p>Select ingredients
                                App suggests cooks offering dishes with selected ingredients
                            </p>

                        </div>
                    </div>
                    <div className='banner-right'>

                        <div className='status'>

                            <h1>Status</h1>
                            <label className="toggle-button">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className='button'>
                            <Button title="Edit" round="full" width="16rem" fill={true} onclick={addBanners}/>
                            <Button title="Delete" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />
                        </div>
                    </div>
                </Cart>
                <Cart>
                    <div className='banner-img-title'>
                        <div>
                            <img src={'https://picsum.photos/200'} alt="img" />
                        </div>
                        <div className='banner-title'>
                            <span>Banner Name </span>
                            <h1>Banner Name </h1>
                            <span>Banner Name </span>
                            <p>Select ingredients
                                App suggests cooks offering dishes with selected ingredients
                            </p>

                        </div>
                    </div>
                    <div className='banner-right'>

                        <div className='status'>

                            <h1>Status</h1>
                            <label className="toggle-button">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className='button'>
                            <Button title="Edit" round="full" width="16rem" fill={true} onclick={addBanners}/>
                            <Button title="Delete" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />
                        </div>
                    </div>
                </Cart>

            </InnerContainer>

            <AddBannersPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        </Container>
    )
}


export default ManageState;