import React, { ChangeEvent, FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../../assets/images/close.svg'
import Button from 'components/particles/primary-button'
import img from '../../../../../../assets/images/input-img-icon.svg'
import imgClose from '../../../../../../assets/images/input-img-close-icon.svg'
interface OnBoardingModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen: boolean
}


const OnBoardingPopup: FC<OnBoardingModelProps> = ({ modalIsOpen, setIsOpen }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState({
        name: '',
        email: '',
        gender: ''
    })
    const [fileName, setFileName] = useState('');

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            setFileName(event.target.files[0].name);
        }
        console.log(selectedImage);

    }
    const search = () => {
        console.log('Search.....', searchQuery);

    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2rem',
            width: '68.5rem',
            height: '60rem',
            borderRadius: '3rem'
        },
    };
    function closeModal() {
        setIsOpen(false);
    }

    function closeImg() {
        setSelectedImage(null)
        setFileName('')
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <button className='close-button' onClick={closeModal}>
                <img src={close} alt="img" />
            </button>
            <div className='model-container'>

                <div className='popup-title' style={{ width: '100%', textAlign: "center", marginTop: "4rem" }}>
                    <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Add Onboarding screen </h2>
                </div>
                <div className='add-ingredient-container'>
                    <div className='image-container'>
                        <div className='image-input-wrapper'>
                            {selectedImage ? (
                                <>
                                    <img className='uploaded-image' src={selectedImage} alt="Selected" />
                                    <img className='close-icon' src={imgClose} alt="Remove" onClick={closeImg} />
                                </>
                            ) : (
                                <>
                                    <label htmlFor="image-input">
                                        <img src={img} alt="Upload" />
                                    </label>
                                    <input
                                        id="image-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className='hidden-input'
                                    />
                                </>
                            )}
                        </div>
                        {fileName && (
                            <div>
                                <p>{fileName}</p> {/* Display the file name */}
                                {/* <p>5.0 MB</p> You can also calculate the file size dynamically */}
                            </div>
                        )}
                        {/* <input type="text" placeholder="Your input here" style={{ flex: 1, border: 'none', backgroundColor: 'transparent', outline: 'none' }} /> */}
                    </div>
                    <input type="text" placeholder='Text 1' />
                    <input type="text" placeholder='Text 2' />
                    <input type="text" placeholder='Text 3' />
                </div>

                <div className='add-button'>
                    <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true} />
                </div>
            </div>
        </Modal>
    )
}

export default OnBoardingPopup