import { FC, useState } from "react";
import { Container, IconContainer, Input } from "./style";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import email from '../../../assets/images/mail.svg'
import password from '../../../assets/images/lock.svg'

interface TextinputProps {
  placeholder?: string;
  onchange: (value: any) => void;
  stateValue?: string;
  width?: string;
  option?: string[]
}

interface IconInputProps {
  placeholder: string;
  onchange: (value: string) => void;
  stateValue: string;
}

export const Textinput: FC<TextinputProps> = ({ placeholder, onchange, stateValue, width }) => {

  return (
    <Container>
      <img src={email} alt="img" width={20} />
      <Input
        type="text"
        placeholder={placeholder}
        onChange={(e) => onchange(e.target.value)}
        value={stateValue}
      />
    </Container>
  );
};
export const SearchIconInput: FC<TextinputProps> = ({ option }) => {
  return (
    <select name="Gender" id="Gender">
      {option?.map((item: string, index: number) => {
        return (
          <option value="Male" key={index}>{item}</option>
        )
      })}
    </select>
  );
};



export const IconInput: FC<IconInputProps> = ({ placeholder, onchange, stateValue }) => {
  const [inputType, setInputType] = useState<'text' | 'password'>('password');

  const handleInputTypeChange = () => {
    setInputType(prevType => (prevType === 'text' ? 'password' : 'text'));
  };

  return (
    <Container>
      <img src={password} alt="img" width={25} height={25} />
      <Input
        type={inputType}
        placeholder={placeholder}
        onChange={(e) => { onchange(e.target.value) }}
        value={stateValue}
      />
      <IconContainer onClick={handleInputTypeChange}>
        {inputType === 'password' ? (
          <FaRegEyeSlash size={18} color='#808D9E' />
        ) : (
          <FaRegEye size={18} color='#808D9E' />
        )}
      </IconContainer>
    </Container>
  );
}