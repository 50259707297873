import React, { ChangeEvent, FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../assets/images/close.svg'
import Button from 'components/particles/primary-button'

interface RetailerModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen: boolean
}


const RetailerPopup: FC<RetailerModelProps> = ({ modalIsOpen, setIsOpen }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState({
        name: '',
        email: '',
        gender: ''
    })

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        console.log(selectedImage);

    }
    const search = () => {
        console.log('Search.....', searchQuery);

    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2rem',
            width: '68.5rem',
            height: '60rem',
            borderRadius: '3rem'
        },
    };
    function closeModal() {
        setIsOpen(false);
    }

    const handleChange = (field: any, value: any) => {
        setSearchQuery({
            ...searchQuery,
            [field]: value
        });
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <button className='close-button' onClick={closeModal}>
                <img src={close} alt="img" />
            </button>
            <div className='model-container'>

                <div>
                    <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Add a New Retailer</h2>
                </div>
                <div className='add-retailer-container'>
                    <input
                        type="text"
                        placeholder='Legal First Name'
                        onChange={(value) => handleChange('name', value)}
                    />
                    <input
                        type="text"
                        placeholder='Legal Last Name'
                        onChange={(value) => handleChange('name', value)}
                    />
                    <input
                        type="text"
                        placeholder='Title (Role in Store)'
                        onChange={(value) => handleChange('name', value)}
                    />
                    <input
                        type="text"
                        placeholder='Email Address'
                        onChange={(value) => handleChange('name', value)}
                    />
                    <input 
                    type="text" 
                    placeholder='Company Name' 
                    style={{ width: '100%' }} 
                    />
                    <input
                        type="text"
                        placeholder='Website / Social media URL'
                        onChange={(value) => handleChange('name', value)}
                    />
                    <input
                        type="text"
                        placeholder='Phone'
                        onChange={(value) => handleChange('name', value)}
                    />
                    <input 
                    type="text" 
                    placeholder='Store Address' 
                    style={{ width: '100%' }} 
                    />

                </div>

                <div className='add-button'>
                    <Button title="Add" round="full" width='51.2rem' backgroundColor='var(--primary)' fill={true} />
                </div>
            </div>
        </Modal>
    )
}

export default RetailerPopup