import { FC } from 'react';
import styled from 'styled-components';

interface PrimaryButtonProps {
  onclick?: () => void;
  fill?: boolean;
  fillColor?: string;
  width?: string;
  round?: string;
  outlined?: boolean;
  outlineColor?: string;
  title?: string;
  backgroundColor?:string;
}

const StyledButton = styled.button<PrimaryButtonProps>`
  height: 5rem;
  width: ${({ width }) => width || '100%'};
  border-radius: 5px;
  background-color:${({backgroundColor}) => (backgroundColor ? backgroundColor : 'var(--primary)')};
  border: ${({ outlined, outlineColor }) => (outlined ? `1px solid ${outlineColor || '#2E4D55'}` : 'none')};
  color: ${({ fill }) => (fill ? 'white' : 'var(--primary)')};
  cursor: pointer;
  text-align: center;
  display: inline-block;
  padding: 0.5rem 1rem;

`;

const Button: FC<PrimaryButtonProps> = ({
  onclick,
  fill,
  fillColor,
  width,
  round,
  outlined,
  outlineColor,
  title,
  backgroundColor
}) => {
  return (
    <StyledButton
      onClick={onclick}
      fill={fill}
      fillColor={fillColor}
      width={width}
      round={round}
      outlined={outlined}
      outlineColor={outlineColor}
      backgroundColor={backgroundColor}
    >
      {title}
    </StyledButton>
  );
};

export default Button;