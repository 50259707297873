import { FC, Fragment, useState } from 'react'
import Pagination from '../../../components/particles/pagination/pagination'
import { Container } from './style'
import RetailerPopup from 'utils/helpers/models/popus/retailer-popup';

import Button from 'components/particles/primary-button';
import { tableData } from 'utils/helpers/dummyData';
import NoDataFound from 'components/particles/no-data-found';
import { useNavigate } from 'react-router-dom';

import view from '../../../assets/images/view-icon.svg';
import deleteIcon from '../../../assets/images/delete.svg'
import { siteRoutes } from 'utils/helpers/enums/routes.enums';

const Retailer: FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    phone: '',
    gender: '',
    company:''
  })
  const navigate = useNavigate()
  const search = () => {
    console.log('Search.....', searchQuery);

  }
  const addRetailer = () => {
    setIsOpen(true)
  }

  const limit = 10;
  function fetchNextRecords(offset: number) {
    console.log(offset);

  }

  const handleChange = (field: any, value: any) => {
    setSearchQuery({
      ...searchQuery,
      [field]: value
    });
  };

  const columns: any = [
    {
      name: 'Name',
      class: ''
    },
    {
      name: 'Email',
      class: ''
    },
    {
      name: 'Phone Number',
      class: ''
    },
    {
      name: 'Compay Name',
      class: 'th'
    },
    {
      name: 'Address',
      class: 'th'
    },
    {
      name: 'Status',
      class: 'th'
    },
    {
      name: 'Actions',
      class: 'th'
    },
  ]
  return (
    <Container>
      <div className='title'>
        <h1>Retailers list</h1>
        <Button title="Add New Retailer" round="full" onclick={addRetailer} width="16.5rem" fill={true} backgroundColor='var(--reset-button)' />

      </div>
      <div className='search-container' >
        <div className='input-fields'>
        <input
            type="text"
            placeholder='Search By Name'
            onChange={(value) => handleChange('name', value)}
          />
          <input
            type="text"
            placeholder='Search By Phone Number'
            onChange={(value) => handleChange('phone', value)}
          />
          <input type="date" name="date" id="date" /> 
          <input
            type="text"
            placeholder='Search By Company'
            onChange={(value) => handleChange('Company', value)}
          />         
        </div>
        <div className='button-fields'>
          <Button title="Search" round="full" width="16rem" fill={true} onclick={search} />
          <Button title="Reset" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />

        </div>
        {/* <Input /> */}
      </div>
      <div className='data-table'>
        <table>
          <thead>
            <tr>{
              columns.map((column: any, index: number) => {
                return <th className={column.class} key={index}>{column.name}</th>
              })
            }
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData
                ?.map((item, index) => (
                  <tr key={index}>
                    <td ><img src={'https://picsum.photos/200'} className='img' alt="img" /> {item.recipeName}</td>
                    <td >{item.mail}</td>
                    <td>{item.phone}</td>
                    <td className='th'>{item.gender}</td>
                    <td className='th'>{item.location}</td>
                    <td className='th'><label className="toggle-button">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label></td>
                    <td className="actions th">
                      <img src={view} alt='img' onClick={() => navigate(siteRoutes.retailerView)} />
                      <img src={deleteIcon} alt='img' />
                    </td>
                  </tr>
                ))
            ) : (
              <NoDataFound />
            )}
          </tbody>
        </table>

      </div>
      <RetailerPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />

      <Fragment>
        <Pagination totalRecords={10} perPage={limit} handleFetchNextRecords={fetchNextRecords} />
      </Fragment>
    </Container>
  )
}


export default Retailer;