import { ChangeEvent, FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../../assets/images/close.svg'
import Button from 'components/particles/primary-button'
import img from '../../../../../../assets/images/input-img-icon.svg'
import { Container } from './style'


interface AddBannersModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen: boolean
}


const AddBannersPopup: FC<AddBannersModelProps> = ({ modalIsOpen, setIsOpen }) => {
    const [searchQuery, setSearchQuery] = useState({
        name: '',
        heading: '',
        description: ''
    })
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        console.log(selectedImage);

    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2rem',
            width: '68.5rem',
            height: '56.8rem',
            borderRadius: '3rem'
        },
    };
    function closeModal() {
        setIsOpen(false);
    }

    function closeImg() {
        setSelectedImage(null)
    }

    const handleChange = (field: any, value: any) => {
        setSearchQuery({
            ...searchQuery,
            [field]: value
        });
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Container>
                <button className='close-button' onClick={closeModal}>
                    <img src={close} alt="img" />
                </button>
                <div className='model-container'>

                    <div className='popup-title' style={{}}>
                        <h2>Add Banner</h2>
                    </div>
                    <div className='add-ingredient-container'>
                        <div className='image-container'>
                            <div className='image-input-wrapper'>
                                {selectedImage ? (
                                    <>
                                        <img className='uploaded-image' src={selectedImage} alt="Selected" />
                                        <img className='close-icon' src={close} alt="Remove" onClick={closeImg} />
                                    </>
                                ) : (
                                    <>
                                        <label htmlFor="image-input">
                                            <img src={img} alt="Upload" />
                                        </label>
                                        <input
                                            id="image-input"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className='hidden-input'
                                        />
                                    </>
                                )}
                            </div>
                            {/* <input type="text" placeholder="Your input here" style={{ flex: 1, border: 'none', backgroundColor: 'transparent', outline: 'none' }} /> */}
                        </div>
                        <input
                            type="text"
                            placeholder='Banner Name'
                            onChange={(value) => handleChange('name', value)}
                        />
                        <input
                            type="text"
                            placeholder='Banner Heading'
                            onChange={(value) => handleChange('heading', value)}
                        />
                        <input
                            type="text"
                            placeholder='Banner Description'
                            onChange={(value) => handleChange('description', value)}
                        />
                        
                    </div>

                    <div className='add-button'>
                        <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true} />
                    </div>
                </div>
            </Container>
        </Modal>
    )
}

export default AddBannersPopup