import React, { useState } from 'react'
import { Container } from './style'
import AddPrivacyPolicyPopup from 'utils/helpers/models/popus/Settings/privacy-policy'
import Button from 'components/particles/primary-button'

export default function PrivacyPolicy() {
  const [modalIsOpen , setIsOpen] = useState(false)
  return (
    <Container>
      <div className='title'>
      <h1>Privacy Policy</h1>
      <Button title="Add  Privacy Policy" round="full" width="22rem" fill={true} backgroundColor='var(--secondary)' onclick={()=>setIsOpen(true)}/>

      </div>

      <AddPrivacyPolicyPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div className='term-and-condition-container'>
      <h1>Privacy Policy</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, recusandae consequuntur vitae est quod repudiandae culpa iste. Doloribus animi earum ipsum, debitis ab ea, unde iste possimus ex voluptatum cupiditate atque amet est eaque deserunt hic aliquid aut vitae tempora quis distinctio nemo magni voluptas? Enim hic quod aut amet eum saepe quam ipsa repellat aperiam velit provident, autem vero harum facilis doloribus ad ut sapiente quisquam. Consectetur minima dolorum animi explicabo provident voluptatem, mollitia, id obcaecati similique sint, ab reiciendis commodi quasi vitae officiis unde. Aut quam fugit labore quisquam at nihil veritatis libero tempore dignissimos sapiente, est magni obcaecati perferendis magnam iure enim minima! Eius architecto aliquam laborum, voluptate delectus illo. Distinctio nesciunt recusandae nobis? Dignissimos, totam, numquam impedit quia excepturi dolorum placeat consequatur quasi nihil ullam atque veniam a iste itaque quidem iure maxime. Ipsam asperiores incidunt aut, deserunt saepe laborum qui mollitia ipsa corrupti ipsum voluptatem voluptas iure aperiam blanditiis beatae nihil corporis a modi repudiandae cumque porro necessitatibus iste ad! Alias consequatur asperiores expedita ipsum aliquid? Accusamus qui placeat labore maxime quod officia odit aut modi sunt fuga! Non architecto consectetur autem odit eius, facere ex, deserunt quis itaque quos similique distinctio ullam vitae. Praesentium?</p>
      <Button title="Edit" round="full" width="16rem" fill={true} backgroundColor='var(--secondary)' onclick={()=>setIsOpen(true)}/>
    </div>
    </Container>
  )
}
