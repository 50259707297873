import  { FC, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../../assets/images/close.svg'
import Button from 'components/particles/primary-button'
import { Container } from './style'
import deleteIcon from '../../../../../../assets/images/delete.svg'

interface OnBoardingModelProps {
    setIsOpen: (isOpen: boolean) => void,
    modalIsOpen: boolean
}


const DietaryChoicePopup: FC<OnBoardingModelProps> = ({ modalIsOpen, setIsOpen }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '2rem',
            width: '68.5rem',
            height: '60rem',
            borderRadius: '3rem'
        },
    };
    function closeModal() {
        setIsOpen(false);
    }


    const [inputFields, setInputFields] = useState([{ id: 1, value: '' }]);

    const handleAddField = () => {
        setInputFields([...inputFields, { id: inputFields.length + 1, value: '' }]);
    };
    const handleRemoveField = (id: number) => {
        setInputFields(inputFields.filter(field => field.id !== id));
    };

    const handleInputChange = (id: number, event: any) => {
        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                return { ...field, value: event.target.value };
            }
            return field;
        });
        setInputFields(newInputFields);
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Container>
                <button className='close-button' onClick={closeModal}>
                    <img src={close} alt="img" />
                </button>
                <div className='model-container'>

                    <div className='popup-title' style={{ width: '100%', textAlign: "center", marginTop: "4rem" }}>
                        <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Add Onboarding screen </h2>
                    </div>
                    <div className='add-ingredient-container'>
                        <input type="text" placeholder='Question' />
                        <input type='text' placeholder='Description' />
                        <div className='add-input'>
                            <input type="text" placeholder='Answer' />
                            <Button title="+Add" round="full" width="11rem" backgroundColor='var(--reset-button)' fill={true} onclick={handleAddField}/>
                        </div>
                        {inputFields.map(field => (
                            <div key={field.id} className="input-container">
                                <input
                                    type="text"
                                    value={field.value}
                                    onChange={event => handleInputChange(field.id, event)}
                                    placeholder={`Input ${field.id}`}
                                    className="input-field"
                                />
                                <img src={deleteIcon} alt="img"  onClick={() => handleRemoveField(field.id)}/>
                            </div>
                        ))}
                    </div>

                    <div className='add-button'>
                        <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true} />
                    </div>
                </div>
            </Container>
        </Modal>
    )
}

export default DietaryChoicePopup