import { tableData } from 'utils/helpers/dummyData'
import {Container} from './style'
import NoDataFound from 'components/particles/no-data-found'
import edit from '../../../../assets/images/edit.svg';
import deleteIcon from '../../../../assets/images/delete.svg'

export default function Ingredients() {
  return (
    <Container>
      <h1>Ingredients</h1>
      <div className='data-table'>
        <table>
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Type</th>
              <th scope="col">Categories</th>
              <th scope="col">Active / Inactive</th>
              <th scope="col">Actions</th>

            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData
                ?.map((item, index) => (
                  <tr key={index}>
                    <td >
                      <div className="flex gap-4 items-center">
                        <img src={'https://picsum.photos/300'} alt="img" width={60} style={{borderRadius:'50%'}} />
                      </div>
                    </td>
                    <td >{item.recipeName}</td>
                    <td >{item.price}</td>
                    <td >{item.quentity}</td>
                    <td >{item.type}</td>
                    <td >{item.categories}</td>
                    <td><label className="toggle-button">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label></td>
                      <td className="actions">                       
                          <img src={edit} alt='img' />
                          <img src={deleteIcon} alt='img' />
                      </td>
                  </tr>
                ))
            ) : (
              <NoDataFound />
            )}
          </tbody>
        </table>
      </div>
    </Container>
  )
}
