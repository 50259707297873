import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import close from '../../../../../assets/images/close.svg'
import camera from "../../../../../assets/images/camera.svg";
import {
  ProfileImageContainer,
  ProfileImageWrapper,
  ProfileImage,
  EditIconContainer
} from './style'
import { useDispatch } from 'react-redux';
import Button from 'components/particles/primary-button';

interface ProfileModelProps {
  setIsOpen: (isOpen: boolean) => void,
  modalIsOpen: boolean
}

interface User {
  username: string;
  email: string;
  image?: string;
}

interface FormData {
  name: string;
  email: string;
}


const ProfilePopup: FC<ProfileModelProps> = ({ modalIsOpen, setIsOpen }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    email: '',
    gender: '',
    price: '',
    quantity: '',
  })

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
    }
    console.log(selectedImage);

  }
  const search = () => {
    console.log('Search.....', searchQuery);

  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '2rem',
      width: '68.5rem',
      height: '70.7rem',
      borderRadius: '3rem'
    },
  };
  function closeModal() {
    setIsOpen(false);
  }


  const [user, setUser] = useState<User | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profileImage, setProfileImage] = useState<string>('https:picsum.photos/300');
  const [formData, setFormData] = useState<FormData>({ name: '', email: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('adminDetail') || 'null');
    if (storedUser) setUser(storedUser);
  }, []);

  useEffect(() => {
    setFormData({ name: user?.username || '', email: user?.email || '' });
    setProfileImage(user?.image || 'picsum.photos/200');
  }, [user]);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {

    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleUpdateProfile = () => {
  };

  const handleChange = (field: any, value: any) => {
    setSearchQuery({
      ...searchQuery,
      [field]: value
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <button className='close-button' onClick={closeModal}>
        <img src={close} alt="img" />
      </button>
      <div className='model-container'>

        <div className='popup-title' style={{ width: '100%', textAlign: "center", marginTop: "4rem" }}>
          <h2 style={{ fontSize: '2rem', fontFamily: "Inter", fontWeight: "600" }}>Edit Profile</h2>
        </div>
        <div className='add-ingredient-container'>
          <ProfileImageWrapper>
            <ProfileImageContainer>
              <ProfileImage
                src={'https://picsum.photos/100'}
                alt="avatar"
                onClick={handleImageClick}
              />
              <EditIconContainer onClick={handleImageClick}>
                <img src={camera} alt='img' />
              </EditIconContainer>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </ProfileImageContainer>
          </ProfileImageWrapper>
          <input
            type="text"
            placeholder='Name'
            onChange={(value) => handleChange('name', value)}
          />
          <input
            type="text"
            placeholder='Price'
            onChange={(value) => handleChange('price', value)}
          />
          <input
            type="text"
            placeholder='Quantity'
            onChange={(value) => handleChange('quantity', value)}
          />
        </div>

        <div className='add-button'>
          <Button title="Add" round="full" width="51.2rem" backgroundColor='var(--primary)' fill={true} />
        </div>
      </div>
    </Modal>
  )
}

export default ProfilePopup