import React, { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import notificationIcon from '../../../assets/images/notification-icon.svg';
import dropdownButton from '../../../assets/images/dropdown.svg'
import {
  ProfileDropdownContainer,
  NavSection,
  Container,
  NotificationContainer,
  NotificationBadge,
  NotificationButton,
  NotificationImg,
  ProfileContainer,
  ProfileImage,
  ProfileData,
  ProfileName,
  ProfileRole,
  DropdownMenu,
  DropdownItem,
  Divider,
  DropdownButton,
  Icon
} from './styles'
import useComponentVisible from 'hooks/click-outside-hook';
import { siteRoutes } from 'utils/helpers/enums/routes.enums';
import { RootState, dispatch } from 'store';
import { setSideBarClosed, setSideBarOpened } from 'store/user-slice';
import { useSelector } from 'react-redux';


const Navbar: React.FC = () => {
  const { isSidebarExpanded } = useSelector((state:RootState) => state.user);
  const [profileImage, setProfileImage] = useState('http://picsum.photos/200');
  const navigate = useNavigate();
  const {
    isComponentVisible: isNotificationVisible,
    ref: notificationRef,
    setIsComponentVisible: setIsNotificationComponentVisible,
  } = useComponentVisible(false);
  const {
    isComponentVisible: profileDropDownOpened,
    ref: profileDropdownRef,
    setIsComponentVisible: setProfileDropdownOpened,
  } = useComponentVisible(false);

  const logout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleExpandSidebar = () => {
    if (isSidebarExpanded) {
      dispatch(setSideBarClosed());
    } else {
      dispatch(setSideBarOpened());
    }
  };

  return (
    <Container className='no-print'>
      <div className='navbar'>
        <div className='title'>
      <div className='wrapper'>
      <Icon
        size={30}
        color="#000000"
        onClick={handleExpandSidebar}
      />
    </div>
          <h1>Hello Admin 👋🏼,</h1>
        </div>
      <NavSection>
        <NotificationContainer>
          <NotificationButton>
            <NotificationBadge>1</NotificationBadge>
            <NotificationImg src={notificationIcon} alt='img' />
          </NotificationButton>
        </NotificationContainer>
        <ProfileContainer onClick={() => setProfileDropdownOpened(!profileDropDownOpened)}>
          <ProfileImage src={profileImage} alt="avatar" />
          <ProfileData>
          <ProfileName>Builtin Soft</ProfileName>
          <ProfileRole>Role</ProfileRole>
          </ProfileData>
          <DropdownButton src={dropdownButton}></DropdownButton>
        </ProfileContainer>
        <div>
          {profileDropDownOpened && (
        <ProfileDropdownContainer ref={profileDropdownRef}>
          <DropdownItem
            onClick={() => {
              setProfileDropdownOpened(false);
              navigate(siteRoutes.profile);
            }}
          >
            Profile
          </DropdownItem>
          <Divider />
          <DropdownItem
            onClick={() => {
              setProfileDropdownOpened(false);
              navigate(siteRoutes.setting);
            }}
          >
            Settings
          </DropdownItem>
          <Divider />
          <DropdownItem onClick={logout}>
            Logout
          </DropdownItem>
        </ProfileDropdownContainer>
      )}
      </div>
        {/* <DropdownMenu>
          <DropdownItem>Profile</DropdownItem>
          <Divider />
          <DropdownItem>Settings</DropdownItem>
          <Divider />
          <DropdownItem onClick={logout}>
            Logout
          </DropdownItem>
        </DropdownMenu> */}
      </NavSection>
      </div>

    </Container>
  );
};

export default Navbar;
