import styled from "styled-components";

export const Container = styled.div`

.quantity{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.quantity-unit{
    width: 180px;
}
`