import { FC, Fragment, useState } from 'react'
import { Container } from './style';
import Pagination from 'components/particles/pagination/pagination';
import { tableData } from 'utils/helpers/dummyData';
import NoDataFound from 'components/particles/no-data-found';
import view from '../../../assets/images/view-icon.svg';
import deleteIcon from '../../../assets/images/delete.svg'
import { useNavigate } from 'react-router-dom';
import { siteRoutes } from 'utils/helpers/enums/routes.enums';
import Button from 'components/particles/primary-button';
import { SearchIconInput } from 'components/particles/forms/input';


const HomeChef: FC = () => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    email: '',
    gender: ''
  })
  const search = () => {
    console.log('Search.....', searchQuery);

  }


  const limit = 10;
  function fetchNextRecords(offset: number) {
    console.log(offset);

  }

  const handleChange = (field: any, value: any) => {
    setSearchQuery({
      ...searchQuery,
      [field]: value
    });
  };

  const columns: any = [
    {
      name: 'Name',
      class: ''
    },
    {
      name: 'Email',
      class: ''
    },
    {
      name: 'Phone Number',
      class: ''
    },
    {
      name: 'Gender',
      class: 'th'
    },
    {
      name: 'Location',
      class: 'th'
    },
    {
      name: 'Status',
      class: 'th'
    },
    {
      name: 'Actions',
      class: 'th'
    },
  ]
  return (
    <Container>
      <h1>Home cook List</h1>
      <div className='search-container' >
        <div className='input-fields'>
        <input
            type="text"
            placeholder='Search By Name'
            onChange={(value) => handleChange('name', value)}
          />
        <input
            type="text"
            placeholder='Search by Phone Number'
            onChange={(value) => handleChange('phone', value)}
          />
        <SearchIconInput placeholder='Status' onchange={(value) => handleChange('email', value)} option={['All' , 'Pending' , 'Declined' , 'Complete']}/>
        <SearchIconInput placeholder='Gender' onchange={(value) => handleChange('gender', value)} option={['Male' , 'Female']}/>

        </div>
        <div className='button-fields'>
          <Button title="Search" round="full" width="16rem" fill={true} onclick={search} />
          <Button title="Reset" round="full" width="16rem" fill={true} backgroundColor='var(--reset-button)' />

        </div>
      </div>
      <div className='data-table'>
        <table>
          <thead>
            <tr>
              {
                columns.map((column: any, index: number) => {
                  return <th className={column.class} key={index}>{column.name}</th>
                })
              }
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData
                ?.map((item, index) => (
                  <tr key={index}>
                    <td ><img src={'https://picsum.photos/200'} alt='img' style={{ width: '40px', height: '40px', borderRadius: '50%' }} /> {item.recipeName}</td>
                    <td >{item.mail}</td>
                    <td>{item.phone}</td>
                    <td className='th'>{item.gender}</td>
                    <td className='th'>{item.location}</td>
                    <td className='th'><label className="toggle-button">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label></td>
                    <td className="actions th">
                      <img src={view} alt='img' onClick={() => navigate(siteRoutes.homeChefView)} />
                      <img src={deleteIcon} alt='img' />
                    </td>
                  </tr>
                ))
            ) : (
              <NoDataFound />
            )}
          </tbody>
        </table>
      </div>
      <Fragment>
        <Pagination totalRecords={10} perPage={limit} handleFetchNextRecords={fetchNextRecords} />
      </Fragment>
    </Container>
  )
}


export default HomeChef;